import React, { useEffect, useState } from "react";
import "../../Styles/T3/T3BattingSummary.css";
import t3_india_logo from "../../Assets/t3_india_img.png";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import qs from "qs";
import team_default_logo from "../../Assets/t3_team_default_img.png";
import T3BowlingSummary from "./T3BowlingSummary";
import he from "he";
function T3BattingSummary() {
  let [searchParams] = useSearchParams();
  let match_id = searchParams.get("match_id");
  const [summary, setSummary] = useState({
    player_names: [],
    team_logo: "",
    team_name: "",
    team_extras: "",
    team_overs: "",
    runs: "",
    wickets: "",
    team_nick_name: "",
    curr_innings: "",
    team_extras: "",
    team_overs: "",
  });
  const [playerDivId, setPlayerDivId] = useState("");
  const [playerNotOutId, setPlayerNotOutId] = useState("t3_batting_player_bg");
  const reqData = {
    mod: "Match",
    actionType: "innings-summary-new",
    subAction: JSON.stringify({ MATCH_ID: match_id }),
  };

  const decodeNames = (x) => {
    if (x) {
      var r = /\\u([\d\w]{4})/gi;
      x = x.replace(r, function (match, grp) {
        return String.fromCharCode(parseInt(grp, 16));
      });
    }

    return x;
  };
  useEffect(() => {
    axios({
      method: "POST",
      url: process.env.REACT_APP_API_URL,
      data: qs.stringify(reqData),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
      },
    }).then((res) => {
      console.log(res, "res");
      let responseData = res.data.XSCData;
      setSummary({
        player_names: responseData.BATTING_TEAM_DETAILS.PLAYER_SCORE_DETAILS,
        team_name: he.decode(responseData.BATTING_TEAM_DETAILS.TEAM_NAME),
        team_logo: responseData.BATTING_TEAM_DETAILS.TEAM_URL,
        team_extras: responseData.BATTING_TEAM_DETAILS.EXTRAS,
        team_overs: responseData.BATTING_TEAM_DETAILS.OVERS,
        runs: responseData.BATTING_TEAM_DETAILS.RUNS,
        wickets: responseData.BATTING_TEAM_DETAILS.WICKETS,
        team_extras: responseData.BATTING_TEAM_DETAILS.EXTRAS,
        team_overs: responseData.BATTING_TEAM_DETAILS.OVERS,
        team_nick_name: he.decode(
          responseData.BATTING_TEAM_DETAILS.TEAM_NICK_NAME
        ),
        curr_innings: responseData.CURRENT_INNINGS,
      });
    });
  }, []);
  const [showTicker, setShowTicker] = useState(true);

  useEffect(() => {
    setTimeout(function () {
      setShowTicker(!showTicker);
      // setShow("none");
    }, 11000);
  }, []);

  const playerNameCount = 15; // Set the maximum letter count
  const strikerElements = document.querySelectorAll(".t3_team_player_name");
  strikerElements.forEach((striker) => {
    if (striker.textContent.length > playerNameCount) {
      const truncatedText = striker.textContent.substring(0, playerNameCount);
      striker.textContent = truncatedText + "...";
    }
  });

  const [show, setShow] = useState(false);
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShow(true); // Assuming you have this state variable to control the visibility of the partnership div
    }, 2000); // Adjust the delay as per your requirement

    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <>
      {showTicker ? (
        show && (
      <div
        id="t3_batting_bg"
        className="d-flex position-relative"
        style={{ height: "100vh" }}
        data-aos="zoom-in"
        data-aos-delay="500"
      >
        <div id="t3_batting_title_bg">
          <img
            src={process.env.REACT_APP_API_URL + summary.team_logo}
            onError={(e) => (e.target.src = team_default_logo)}
            alt="t3_logo"
          />
          <div id="t3_batting_team_name">
            {he.decode(decodeNames(summary.team_nick_name))}
          </div>
          <div id="t3_batting_text">
            <div id="t3_batting_summary_text">BATTING SUMMARY</div>
            <div id="inning_text">
              {summary.curr_innings === "1" ? "INNINGS 1" : ""}
              {summary.curr_innings === "2" ? "INNINGS 2" : ""}
            </div>
          </div>
        </div>
        <div id="t3_blue_bg">
          <div className="row" id="t3_batting_row">
            <div className="col-12  w-100 p-0 m-0">
              {summary.player_names === undefined
                ? ""
                : summary.player_names
                    .slice(0, 11)
                    // .sort(function (a, b) {
                    //   return b.RUNS - a.RUNS;
                    // })
                    .map((players) => {
                      return (
                        <div className="row">
                          {" "}
                          <div
                            id={
                              players.OUT_TEXT === "NOT OUT"
                                ? "t3_batting_player_bg"
                                : "t3_batting_player_blue_bg"
                            }
                          >
                            <div
                              id="t3_batting_player_name"
                              className="t3_team_player_name"
                            >
                              {decodeNames(he.decode(players.NAME))}
                            </div>
                            <div id="t3_batting_player_out_type">
                              {players.OUT_TEXT === "NOT OUT"
                                ? players.OUT_TEXT
                                : players.OUT_TEXT === "OUT"
                                ? players.HOW_OUT === "BOWLED"
                                  ? ""
                                  : players.HOW_OUT === "CAUGHT"
                                  ? "c " + players.FIELDER
                                  : players.HOW_OUT
                                : ""}
                            </div>
                            <div id="t3_batting_bowler">
                              {players.HOW_OUT === "BOWLED"
                                ? "b " + decodeNames(he.decode(players.BOWLER))
                                : players.HOW_OUT === "CAUGHT"
                                ? "b " + decodeNames(he.decode(players.BOWLER))
                                : ""}
                            </div>
                          </div>
                          <div id="t3_batting_player_runs_bg">
                            <div id="t3_batting_player_runs" align="end">
                              {players.RUNS}
                            </div>
                            <div id="t3_batting_player_balls">
                              {players.BALLS}
                            </div>
                          </div>
                        </div>
                      );
                    })}

              {/* <div className="row">
                    {" "}
                    <div id="t3_batting_player_extras_bg">
                      <div id="t3_bowling_extras">
                        extras {summary.team_extras}
                      </div>
                      <div id="t3_bowling_bottom_overs">
                        overs {summary.team_overs}
                      </div>
                    </div>
                    <div id="t3_batting_overs_bg">
                      <div id="t3_bowling_runs_wickets">
                        {summary.runs}-{summary.wickets}
                      </div>
                    </div>
                  </div> */}
            </div>
            <div className="row d-flex align-items-center ms-5 ps-3" style={{paddingLeft:"2vw"}}>
              {" "}
              <div
                id="t3_bowling_player_extras_bg"
                style={{ left: "3.2vw", width: "46.5vw" }}
              >
                <div id="t3_bowling_extras">extras {summary.team_extras}</div>
                <div id="t3_bowling_bottom_overs">
                  overs {summary.team_overs}
                </div>
              </div>
              <div
                id="t3_bowling_overs_bg"
                style={{ left: "49vw", width: "12.4vw" }}
              >
                <div
                  id="t3_bowling_runs_wickets"
                  className="d-flex align-items-center justify-content-center"
                >
                  {summary.runs}-{summary.wickets}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
           )
         ) : (
           <T3BowlingSummary />
         )}
    </>
  );
}

export default T3BattingSummary;
