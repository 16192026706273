import React from "react";
import "../../Styles/T3/T3BowlingSummary.css";
import india_logo from "../../Assets/t3_india_img.png";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import qs from "qs";
import { useState, useEffect } from "react";
import team_default_logo from "../../Assets/t3_team_default_img.png";
import T3InningsBreak from "./T3InningsBreak";
import socket from "socket.io-client/lib/socket";
import socketIOClient from "socket.io-client";
import he from "he";
import T3MatchSummary from "./T3MatchSummary";

function T3BowlingSummary() {
  let [searchParams] = useSearchParams();
  let match_id = searchParams.get("match_id");
  const ENDPOINT = process.env.REACT_APP_SOCKET_URL;
  const [summary, setSummary] = useState({
    player_names: [],
    team_logo: "",
    fall_of_wicket: [],
    score: [],
    extras: "",
    runs: "",
    wickets: "",
    team_name: "",
    overs: "",
    team_nick_name: "",
    curr_innings: "",
  });
  const decodeNames = (x) => {
    // var x = "http\\u00253A\\u00252F\\u00252Fexample.com";
    if (x) {
      var r = /\\u([\d\w]{4})/gi;
      x = x.replace(r, function (match, grp) {
        return String.fromCharCode(parseInt(grp, 16));
      });
    }

    return x;
  };
  const reqData = {
    mod: "Match",
    actionType: "innings-summary-new",
    subAction: JSON.stringify({ MATCH_ID: match_id }),
  };
  useEffect(() => {
    axios({
      method: "POST",
      url: process.env.REACT_APP_API_URL,
      data: qs.stringify(reqData),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
      },
    }).then((res) => {
      console.log(res, "res");
      let responseData = res.data.XSCData;
      setSummary({
        player_names: responseData.BOWLING_TEAM_DETAILS.PLAYER_SCORE_DETAILS,
        team_name: responseData.BOWLING_TEAM_DETAILS.TEAM_NAME,
        overs: responseData.BOWLING_TEAM_DETAILS.OVERS,
        runs: responseData.BOWLING_TEAM_DETAILS.RUNS,
        wickets: responseData.BOWLING_TEAM_DETAILS.WICKETS,
        fall_of_wicket: responseData.BATTING_TEAM_DETAILS.FALL_OF_WICKET,
        team_logo: responseData.BOWLING_TEAM_DETAILS.TEAM_URL,
        curr_innings: responseData.CURRENT_INNINGS,
        team_nick_name: responseData.BOWLING_TEAM_DETAILS.TEAM_NICK_NAME,
        extras: responseData.BOWLING_TEAM_DETAILS.EXTRAS,
        curr_innings: responseData.CURRENT_INNINGS,
        wickets: responseData.BOWLING_TEAM_DETAILS.WICKETS,
        extras: responseData.BOWLING_TEAM_DETAILS.EXTRAS,
        status: responseData.STATUS,
      });
    });
  }, [0]);
  const [showTicker, setShowTicker] = useState(true);

  useEffect(() => {
    setTimeout(function () {
      setShowTicker(!showTicker);
      // setShow("none");
    }, 8000);
  }, []);

  useEffect(() => {
    const socket = socketIOClient(ENDPOINT);
    socket.on("connected", (data) => {});
    socket.on("live_score_new", (data) => {
      console.log(data);
    });
    socket.emit("join", "match_id", match_id);
    socket.emit("get_score", match_id);
    return () => socket.disconnect();
  }, []);


  const playerNameCount = 15; // Set the maximum letter count
const strikerElements = document.querySelectorAll(".t3_bowling_player_name");
strikerElements.forEach(striker => {
  if (striker.textContent.length > playerNameCount) {
    const truncatedText = striker.textContent.substring(0, playerNameCount);
    striker.textContent = truncatedText + "...";
  }
});


  return (
    <>
      {showTicker ? (
      <div
        id="t3_bowling_bg"
        style={{ height: "100vh" }}
        data-aos="zoom-in"
        data-aos-delay="500"
      >
        <div id="t3_bowling_title_bg">
          <img
            src={process.env.REACT_APP_API_URL + summary.team_logo}
            onError={(e) => (e.target.src = team_default_logo)}
            alt="t3_teama_logo"
          />
          <div id="t3_bowling_team_name">
            {decodeNames(summary.team_nick_name)}
          </div>
          {/* <div id="t3_bowling_summary_text">BOWLING SUMMARY</div> */}
          <div id="t3_bowling_text">
            <div id="t3_bowling_summary_text">BOWLING SUMMARY</div>
            <div id="inning_text">
              {summary.curr_innings === "1" ? "INNINGS - 1" : ""}
              {summary.curr_innings === "2" ? "INNINGS - 2" : ""}
            </div>
          </div>
        </div>
        <div id="t3_bowling_blue_bg">
          <div>
            <div id="t3_bowling_details_title_bg">
              <div className="row">
                <div className="col-12">
                  <div id="t3_bowling_overs_text">overs</div>
                  <div id="t3_bowling_dots_text">dots</div>
                  <div id="t3_bowling_runs_text">Runs</div>
                  <div id="t3_bowling_wickets_text">wickets</div>
                  <div id="t3_bowling_economy_text">Economy</div>
                </div>
              </div>
            </div>

            {summary.player_names === undefined
              ? ""
              : summary.player_names
                  .filter((player) => player.OVERS !== null)
                  .slice(0, 8)
                  .sort((a, b) => {
                    // Sort by wickets first
                    if (b.WICKETS !== a.WICKETS) {
                      return b.WICKETS - a.WICKETS; // Higher wickets first
                    } else {
                      // If wickets are the same, sort by economy
                      return a.ECONOMY - b.ECONOMY; // Lower economy first
                    }
                  })
                  .map((bowler) => {
                    return (
                      <div id="t3_bowling_players_bg">
                        <div id="t3_bowling_player_name_bg">
                          <div id="t3_bowling_player_name" className="t3_bowling_player_name">
                            {decodeNames(bowler.NAME)}
                          </div>
                        </div>
                        <div id="t3_bowling_player_data_bg">
                          <div id="t3_bowling_overs">{bowler.OVERS}</div>
                          <div id="t3_bowling_dots">{bowler.DOT_BALLS}</div>
                          <div id="t3_bowling_runs">{bowler.RUNS}</div>
                          <div id="t3_bowling_wickets">{bowler.WICKETS}</div>
                          <div id="t3_bowling_economy">{bowler.ECONOMY}</div>
                        </div>
                      </div>
                    );
                  })}
          </div>
          <div>
            <div className="position-absolute" id="t3_fall">
              <div id="t3_bowling_fallen_wicket_bg">
                <div id="t3_bowling_fallen_wicket_text_bg">
                  <div id="t3_fallen_wicket_text">FALL OF WICKETS</div>
                </div>
                <div id="t3_bowling_fallen_wicket_data_bg">
                  <div className="row w-100">
                    {summary.fall_of_wicket === undefined ? (
                      ""
                    ) : summary.fall_of_wicket === null ? (
                      <div id="" className="col-1"></div>
                    ) : (
                      summary.fall_of_wicket.map((wickets) => {
                        return (
                          <div
                            id=""
                            className="col-1 d-flex align-items-center justify-content-center"
                          >
                            {wickets.WICKET}
                          </div>
                        );
                      })
                    )}
                  </div>
                </div>
              </div>
              <div id="t3_bowling_fallen_wicket_bg">
                <div id="t3_bowling_fallen_wicket_text_bg">
                  <div id="t3_fallen_wicket_text">SCORES</div>
                </div>
                <div id="t3_bowling_fallen_wicket_score_bg">
                  <div className="row w-100">
                    {summary.fall_of_wicket === undefined ? (
                      ""
                    ) : summary.fall_of_wicket === null ? (
                      <div className="col-1"></div>
                    ) : (
                      summary.fall_of_wicket.map((runs) => {
                        return (
                          <div className="col-1 d-flex align-items-center justify-content-center">
                            {runs.TEAM_RUNS}
                          </div>
                        );
                      })
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row d-flex align-items-center ">
              {" "}
              <div id="t3_bowling_player_extras_bg">
                <div id="t3_bowling_extras">extras {summary.extras}</div>
                <div id="t3_bowling_bottom_overs">overs {summary.overs}</div>
              </div>
              <div id="t3_bowling_overs_bg">
                <div
                  id="t3_bowling_runs_wickets"
                  className="d-flex align-items-center justify-content-center"
                >
                  {summary.runs}-{summary.wickets}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
       ) : summary.status === "INNINGS_BREAK" ? (
        <T3InningsBreak />
      ) : summary.status === "COMPLETED" ? (
        <T3MatchSummary />
      ) : (
        ""
      )} 
    </>
  );
}

export default T3BowlingSummary;
